var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.dayList,"label":"Tag"},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.epoList,"label":"Epo"},model:{value:(_vm.selectedEpo),callback:function ($$v) {_vm.selectedEpo=$$v},expression:"selectedEpo"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.member,"search":_vm.search,"show-group-by":"","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"header.building_0",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Lokschuppen ("+_vm.epoTargets[_vm.selectedEpo][0]+")")+" ")]}},{key:"header.building_1",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Bahnhofshalle ("+_vm.epoTargets[_vm.selectedEpo][1]+")")+" ")]}},{key:"header.building_2",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Schienenhalle ("+_vm.epoTargets[_vm.selectedEpo][2]+")")+" ")]}},{key:"header.building_3",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Bauhof ("+_vm.epoTargets[_vm.selectedEpo][3]+")")+" ")]}},{key:"header.building_4",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Bank ("+_vm.epoTargets[_vm.selectedEpo][4]+")")+" ")]}},{key:"header.building_5",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Lizenz ("+_vm.epoTargets[_vm.selectedEpo][5]+")")+" ")]}},{key:"header.building_6",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Labor ("+_vm.epoTargets[_vm.selectedEpo][6]+")")+" ")]}},{key:"header.building_7",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Hotel ("+_vm.epoTargets[_vm.selectedEpo][7]+")")+" ")]}},{key:"header.building_8",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Restaurant ("+_vm.epoTargets[_vm.selectedEpo][8]+")")+" ")]}},{key:"header.building_9",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text = "Einkaufszentrum ("+_vm.epoTargets[_vm.selectedEpo][9]+")")+" ")]}},{key:"item.prestige",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat().format(item.prestige))+" ")]}},{key:"item.building_0",fn:function(ref){
var item = ref.item;
return [(item.building_0)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(0, item.building_0, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_0)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_0_diff)+" ")]}},{key:"item.building_1",fn:function(ref){
var item = ref.item;
return [(item.building_1)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(1, item.building_1, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_1)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_1_diff)+" ")]}},{key:"item.building_2",fn:function(ref){
var item = ref.item;
return [(item.building_2)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(2, item.building_2, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_2)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_2_diff)+" ")]}},{key:"item.building_3",fn:function(ref){
var item = ref.item;
return [(item.building_3)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(3, item.building_3, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_3)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_3_diff)+" ")]}},{key:"item.building_4",fn:function(ref){
var item = ref.item;
return [(item.building_4)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(4, item.building_4, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_4)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_4_diff)+" ")]}},{key:"item.building_5",fn:function(ref){
var item = ref.item;
return [(item.building_5)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(5, item.building_5, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_5)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_5_diff)+" ")]}},{key:"item.building_6",fn:function(ref){
var item = ref.item;
return [(item.building_6)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(6, item.building_6, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_6)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_6_diff)+" ")]}},{key:"item.building_7",fn:function(ref){
var item = ref.item;
return [(item.building_7)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(7, item.building_7, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_7)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_7_diff)+" ")]}},{key:"item.building_8",fn:function(ref){
var item = ref.item;
return [(item.building_8)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(8, item.building_8, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_8)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_8_diff)+" ")]}},{key:"item.building_9",fn:function(ref){
var item = ref.item;
return [(item.building_9)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor(9, item.building_9, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_9)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_9_diff)+" ")]}},{key:"item.building_all",fn:function(ref){
var item = ref.item;
return [(item.building_all)?_c('v-chip',{attrs:{"color":_vm.getEpoBuildingColor('all', item.building_all, item.entryday),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.building_all)+" ")]):_vm._e(),_vm._v(" +"+_vm._s(item.building_all_diff)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }