<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <v-col>
          Anschlussliste
        </v-col>

          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :search="search"
        :items="trackList"
        show-group-by
        hide-default-footer
        disable-pagination
        dense
    >



      <template v-slot:item.minsk_city="{ item }">
        <v-chip
                :color="(item.minsk_city?'green':'orange')"
                dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.minsk_whz="{ item }">
        <v-chip
                :color="(item.minsk_whz?'green':(item.minsk_city?'red':'orange'))"
                dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.stp_city="{ item }">
        <v-chip
            :color="(item.stp_city?'green':'orange')"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.stp_whz="{ item }">
        <v-chip
            :color="(item.stp_whz?'green':(item.stp_city?'red':'orange'))"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.moskau_city="{ item }">
        <v-chip
            :color="(item.moskau_city?'green':'orange')"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.moskau_whz="{ item }">
        <v-chip
            :color="(item.moskau_whz?'green':(item.moskau_city?'red':'orange'))"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.donezk_city="{ item }">
        <v-chip
            :color="(item.donezk_city?'green':'orange')"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.donezk_whz="{ item }">
        <v-chip
            :color="(item.donezk_whz?'green':(item.donezk_city?'red':'orange'))"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.kiew_city="{ item }">
        <v-chip
            :color="(item.kiew_city?'green':'orange')"
            dark small
        >
        </v-chip>
      </template>

      <template v-slot:item.kiew_whz="{ item }">
        <v-chip
            :color="(item.kiew_whz?'green':(item.kiew_city?'red':'orange'))"
            dark small
        >
        </v-chip>
      </template>



    </v-data-table>

  </v-card>
</template>

<script>

import _ from 'lodash-es/lodash'

export default {
name: "Tracks",
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'username',
        groupable: false,
      },
      { text: 'Gesellschaft', value: 'companyname' },
      { text: 'Minsk', align: 'center', value: 'minsk_city',  groupable: false },
      { text: 'Minsk WHZ', align: 'center', value: 'minsk_whz',  groupable: false },
      { text: 'STP', align: 'center', value: 'stp_city',  groupable: false },
      { text: 'STP WHZ', align: 'center', value: 'stp_whz',  groupable: false },
      { text: 'Moskau', align: 'center', value: 'moskau_city',  groupable: false },
      { text: 'Moskau WHZ', align: 'center', value: 'moskau_whz',  groupable: false },
      { text: 'Donezk', align: 'center', value: 'donezk_city',  groupable: false },
      { text: 'Donezk WHZ', align: 'center', value: 'donezk_whz',  groupable: false },
      { text: 'Kiew', align: 'center', value: 'kiew_city',  groupable: false },
      { text: 'Kiew WHZ', align: 'center', value: 'kiew_whz',  groupable: false },


    ],
    tracks:null,

  }),
  created() {
  var me = this;
    fetch(`https://blc.gamers-universe.eu/api/getPlayerTracks.php`)
    .then((response) => response.json())
    .then((json) => {
     me.tracks =json;






    })
    .catch((error) => console.log(error));
  },
  computed: {
    trackList() {


      if(this.tracks) {

        var list = [];
        _.each(this.tracks, function(value, username) {
          console.log(value);
          console.log(username);
          var member = {}
            member.username = username
            member.companyname = value[0].companyname
          _.each(value, function(track, key) {
            if(!member.minsk_city) {
              member.minsk_city = _.includes(track, 'c891698b-abef-44e6-ac7c-d4dcd2032cdd')
            }
            if(!member.minsk_whz) {
              member.minsk_whz = _.includes(track, 'a020898b-c4d0-4012-a1d9-47d2d25a69b2')
            }
            if(!member.stp_city) {
              member.stp_city = _.includes(track, 'b26c5d28-0b88-406e-ae68-ebda130c7874')
            }
            if(!member.stp_whz) {
              member.stp_whz = _.includes(track, '6f9d8b62-ac09-4ec0-a8c6-86ebf8a4609e')
            }
            if(!member.moskau_city) {
              member.moskau_city = _.includes(track, 'c7857d84-d736-4326-8cf3-0991ec7e6bb1')
            }
            if(!member.moskau_whz) {
              member.moskau_whz = _.includes(track, 'd0d46658-3503-4a0c-a62a-e28c7e382e03')
            }
            if(!member.donezk_city) {
              member.donezk_city = _.includes(track, 'e18d3566-c7db-4bf0-a668-2ed5f0267e31')
            }
            if(!member.donezk_whz) {
              member.donezk_whz = _.includes(track, '6a8845bc-68d5-43e3-89e2-893dcc6e41b8')
            }
            if(!member.kiew_city) {
              member.kiew_city = _.includes(track, 'd3b43a44-7590-472b-a2a4-1da4d763c404')
            }
            if(!member.kiew_whz) {
              member.kiew_whz = _.includes(track, '7d1166a8-d36a-4abd-8d1f-0ebcad8bb2e6')
            }
          })

          list.push(member)

        })

        return list;
      }
      return [];
    }
  },
  methods: {

  },
}
</script>

<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .5);
}
</style>
