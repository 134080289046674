import Vue from 'vue'
import VueRouter from 'vue-router'
import MemberList from '../views/member/List'
import MemberTracks from '../views/member/Tracks'
import MemberDiagram from '../views/member/Diagramm'
import CompanyDiagram from '../views/company/Diagramm'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/member/list',
    name: 'Mitgliederliste',
    component: MemberList
  },
  {
    path: '/member/diagramm',
    name: 'Mitgliederdiagramm',
    component: MemberDiagram
  },
  {
    path: '/member/tracks',
    name: 'Mitgliederanschlüsse',
    component: MemberTracks
  },
  {
    path: '/company/diagramm',
    name: 'Companydiagramm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CompanyDiagram
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
