<template>
  <div>
    <div v-for="itm in items" :key="itm.text">
      <v-list-item
        v-if="itm.type === 'item'"
        :to="itm.route"
        :href="itm.link"
        :target="itm.destination === 'external' ? '_blank' : ''"
        :style="'margin-left: ' + level * 20 + 'px;'"
      >
        <v-list-item-icon>
          <v-icon class="icon">{{ itm.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="item-text">{{ itm.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-if="itm.type === 'group'"
        :prepend-icon="itm.icon"
        :value="false"
        :style="'margin-left: ' + level * 20 + 'px;'"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ itm.text }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <List v-bind:items="itm.subitems" v-bind:level="level + 1" />
      </v-list-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  props: {
    items: {
      type: Array,
    },
    level: { type: Number },
  },
};
</script>

<style scoped>
.primary--text {
  color: #ffffff !important;
}
.v-list .v-list-item--active {
  color: #ffffff !important;
}
.v-list .v-list-group--active {
  color: #ffffff !important;
}
</style>
