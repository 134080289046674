<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
              :items="modes"
              label="Modus"
              v-model="selectedMode"
          ></v-select>
        </v-col>
      </v-row>


    </v-card-title>

    <div id="chartdiv" style="height: 700px"></div>

  </v-card>

</template>

<script>
import _ from 'lodash-es/lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);

export default {
name: "Diagramm",
  data: () => ({
    search: '',
    statisticData: null,
    chart: null,
    filteredData: null,
    selectedMode: "Tagesvergleich",
    modes: [
      "Tagesvergleich",
      "Epochenvergleich",
      "Rundenvergleich",
    ],
    colors: {}
  }),
  computed: {

  },
  methods: {
    getFilteredData() {
      var me = this;
      let filteredData;
      //console.error(me.search);
      if(me.search != "") {
        var search = me.search;
        filteredData = _.filter(me.statisticData, function (o) {
          return o.companyname.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || o.companyname.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        })
      } else {
        filteredData = me.statisticData;
      }

      let tempData= {};
      let sortedData= [];

      _.each(filteredData, function(value, key){
        if(!tempData[value.companyname]) {
          tempData[value.companyname]= {};
        }
        tempData[value.companyname][value.entryday] = parseInt(value.prestige);
      });

      _.each(tempData, function(value, key){
        let item = value;
        item.companyname = key;

        sortedData.push(item)
      });

      let sortedfilteredData = _.orderBy(sortedData,[item => item.companyname.toLowerCase()], ['asc']);
      me.filteredData = sortedfilteredData;
      //console.log(sortedfilteredData);
      return sortedfilteredData;
    },
    updateSeries(){
      var me = this;
      let dataRow = me.filteredData[0];

      //console.log(dataRow);
      while (me.chart.series.length > 0) {
        me.chart.series.removeIndex(0).dispose();
      }

      _.each(dataRow, function(value,key){
        if(key!="companyname"){
          me.createSeries(key,key)
        }
      });

      var range = me.chart.yAxes.values[0].axisRanges.values[0];
      var datakeys = Object.keys(dataRow)
      var sum =_.meanBy(me.filteredData, datakeys[datakeys.length-2]);

      range.value = sum;
      range.endValue = sum;
      //console.warn(me.chart.yAxes.values[0].axisRanges.values[0])

    },
    createSeries(value, name) {
      var me = this;
      let series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'companyname'
      if(this.selectedMode === "Epochenvergleich"){
        series.name = "Epo "+name
      } else {
        series.name = "Tag "+name
      }

      //series.tooltipText = "{valueY.value}"
      series.columns.template.tooltipText = "{name}: {categoryX}: {valueY}";
      //series.events.on("hidden", arrangeColumns);
      //series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 20;
      bullet.rotation= 270;
      bullet.width = 100;
      bullet.label.text = '{valueY}'
      bullet.label.fontSize = 12;
      bullet.label.truncate = false;
      bullet.label.maxWidth = 100;
      //bullet.label.fill = am4core.color('#ffffff')

     /* series.columns.template.adapter.add("fill", function(fill, target) {
        console.log(target);
        let name = target.dataItem.dataContext.userName;
        console.log(name);
        if (!me.colors[name]) {
          me.colors[name] = me.chart.colors.next();
        }

        target.stroke = me.colors[name];

        return me.colors[name];
      })*/

      return series;
    }
  },
  watch: {
      search(newValue) {
        if(newValue.length >2) {
         // console.log("eatch search");
          var me = this;
          let filteredData = me.getFilteredData()
          //console.warn(filteredData);
          this.chart.data = filteredData;
          this.updateSeries();
        }
      },
    selectedMode(newValue) {


      var me = this;
      fetch(`https://blc.gamers-universe.eu/api/getCompanyStats.php?mode=`+newValue)
      .then((response) => response.json())
      .then((json) => {
        me.statisticData =json;



        let data = me.getFilteredData();

        me.chart.data = data;
        /*let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "entryday";
        series.dataFields.valueY = "DeepNorth";
        series.name = "DeepNorth";
        series.tooltipText = "{valueY.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusTopRight = 10;
        series.columns.template.column.cornerRadiusTopLeft = 10;*/
        me.updateSeries();


      })
      .catch((error) => console.log(error));


    }


  },
  mounted() {
  var me = this;
    fetch(`https://blc.gamers-universe.eu/api/getCompanyStats.php`)
    .then((response) => response.json())
    .then((json) => {
      me.statisticData =json;

      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.colors.step = 2;

      let title = chart.titles.create();
      title.text = "Prestige";
      title.fontSize = 25;

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "companyname";
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.cellStartLocation = 0.1
      categoryAxis.renderer.cellEndLocation = 0.9
      categoryAxis.renderer.grid.template.location = 0;

      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.rotation = 270;
      categoryAxis.renderer.labels.template.dx = -20;
      categoryAxis.renderer.labels.template.hideOversized = false;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.extraMax = 0.1;






      /*let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "entryday";
      series.dataFields.valueY = "prestige";
      series.tooltipText = "{valueY.value}"
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.cornerRadiusTopLeft = 10;

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.verticalCenter = "bottom";
      labelBullet.label.dy = -10;
      labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";

      categoryAxis.sortBySeries = series;*/

      let data = me.getFilteredData();
      chart.data = data;
      me.chart = chart;

      var range = valueAxis.axisRanges.create();

      range.axisFill.fill = am4core.color("#f00");
      range.axisFill.fillOpacity = 0.2;
      range.grid.strokeOpacity = 1;
      range.grid.stroke = am4core.color("#f00")

      /*let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "entryday";
      series.dataFields.valueY = "DeepNorth";
      series.name = "DeepNorth";
      series.tooltipText = "{valueY.value}"
      series.columns.template.strokeOpacity = 0;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.cornerRadiusTopLeft = 10;*/
      me.updateSeries();


    })
    .catch((error) => console.log(error));


  }

}
</script>

<style scoped>

</style>
