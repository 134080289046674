<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <v-col>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
      ></v-text-field>
        </v-col>
        <v-col>
          <v-select
              :items="dayList"
              label="Tag"
              v-model="selectedDay"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
              :items="epoList"

              label="Epo"
              v-model="selectedEpo"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="member"
        :search="search"
        show-group-by
        hide-default-footer
        disable-pagination
        dense
    >

      <template v-slot:header.building_0="{ header }">

        {{ header.text = "Lokschuppen ("+epoTargets[selectedEpo][0]+")" }}
      </template>

      <template v-slot:header.building_1="{ header }">
        {{ header.text = "Bahnhofshalle ("+epoTargets[selectedEpo][1]+")" }}
      </template>

      <template v-slot:header.building_2="{ header }">
        {{ header.text = "Schienenhalle ("+epoTargets[selectedEpo][2]+")" }}
      </template>

      <template v-slot:header.building_3="{ header }">
        {{ header.text = "Bauhof ("+epoTargets[selectedEpo][3]+")" }}
      </template>

      <template v-slot:header.building_4="{ header }">
        {{ header.text = "Bank ("+epoTargets[selectedEpo][4]+")" }}
      </template>

      <template v-slot:header.building_5="{ header }">
        {{ header.text = "Lizenz ("+epoTargets[selectedEpo][5]+")" }}
      </template>

      <template v-slot:header.building_6="{ header }">
        {{ header.text = "Labor ("+epoTargets[selectedEpo][6]+")" }}
      </template>

      <template v-slot:header.building_7="{ header }">
        {{ header.text = "Hotel ("+epoTargets[selectedEpo][7]+")" }}
      </template>

      <template v-slot:header.building_8="{ header }">
        {{ header.text = "Restaurant ("+epoTargets[selectedEpo][8]+")" }}
      </template>
      <template v-slot:header.building_9="{ header }">
        {{ header.text = "Einkaufszentrum ("+epoTargets[selectedEpo][9]+")" }}
      </template>



      <template v-slot:item.prestige="{ item }">
        {{ new Intl.NumberFormat().format(item.prestige) }}
      </template>

      <template v-slot:item.building_0="{ item }">
        <v-chip v-if="item.building_0"
            :color="getEpoBuildingColor(0, item.building_0, item.entryday)"
            dark small
        >
          {{ item.building_0 }}
        </v-chip> +{{ item.building_0_diff }}
      </template>

      <template v-slot:item.building_1="{ item }">
        <v-chip v-if="item.building_1"
                :color="getEpoBuildingColor(1, item.building_1, item.entryday)"
                dark small
        >
          {{ item.building_1 }}
        </v-chip> +{{ item.building_1_diff }}
      </template>

      <template v-slot:item.building_2="{ item }">
        <v-chip v-if="item.building_2"
                :color="getEpoBuildingColor(2, item.building_2, item.entryday)"
                dark small
        >
          {{ item.building_2 }}
        </v-chip> +{{ item.building_2_diff }}
      </template>

      <template v-slot:item.building_3="{ item }">
        <v-chip v-if="item.building_3"
                :color="getEpoBuildingColor(3, item.building_3, item.entryday)"
                dark small
        >
          {{ item.building_3 }}
        </v-chip> +{{ item.building_3_diff }}
      </template>

      <template v-slot:item.building_4="{ item }">
        <v-chip v-if="item.building_4"
                :color="getEpoBuildingColor(4, item.building_4, item.entryday)"
                dark small
        >
          {{ item.building_4 }}
        </v-chip> +{{ item.building_4_diff }}
      </template>


      <template v-slot:item.building_5="{ item }">
        <v-chip v-if="item.building_5"
                :color="getEpoBuildingColor(5, item.building_5, item.entryday)"
                dark small
        >
          {{ item.building_5 }}
        </v-chip> +{{ item.building_5_diff }}
      </template>
      <template v-slot:item.building_6="{ item }">
        <v-chip v-if="item.building_6"
                :color="getEpoBuildingColor(6, item.building_6, item.entryday)"
                dark small
        >
          {{ item.building_6 }}
        </v-chip> +{{ item.building_6_diff }}
      </template>
      <template v-slot:item.building_7="{ item }">
        <v-chip v-if="item.building_7"
                :color="getEpoBuildingColor(7, item.building_7, item.entryday)"
                dark small
        >
          {{ item.building_7 }}
        </v-chip> +{{ item.building_7_diff }}
      </template>
      <template v-slot:item.building_8="{ item }">
        <v-chip v-if="item.building_8"
                :color="getEpoBuildingColor(8, item.building_8, item.entryday)"
                dark small
        >
          {{ item.building_8 }}
        </v-chip> +{{ item.building_8_diff }}
      </template>
      <template v-slot:item.building_9="{ item }">
        <v-chip v-if="item.building_9"
                :color="getEpoBuildingColor(9, item.building_9, item.entryday)"
                dark small
        >
          {{ item.building_9 }}
        </v-chip> +{{ item.building_9_diff }}
      </template>
      <template v-slot:item.building_all="{ item }">
        <v-chip v-if="item.building_all"
                :color="getEpoBuildingColor('all', item.building_all, item.entryday)"
                dark small
        >
          {{ item.building_all }}
        </v-chip> +{{ item.building_all_diff }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

import _ from 'lodash-es/lodash'

export default {
name: "List",
  data: () => ({
    search: '',
    selectedDay: '',
    selectedEpo: 1,
    dayList: [],
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'username',
        groupable: false,
      },
      { text: 'Gesellschaft', value: 'companyname' },
      { text: 'Prestige', align: 'end', value: 'prestige',  groupable: false },
      { text: 'Lokschuppen',align: 'center', value: 'building_0',  groupable: false },
      { text: 'Bahnhofshalle', align: 'center',value: 'building_1',  groupable: false },
      { text: 'Schienenhalle', align: 'center',value: 'building_2',  groupable: false },
      { text: 'Bauhof', align: 'center',value: 'building_3',  groupable: false },
      { text: 'Bank', align: 'center',value: 'building_4',  groupable: false },
      { text: 'Lizenz', align: 'center',value: 'building_5',  groupable: false },
      { text: 'Labor', align: 'center',value: 'building_6',  groupable: false },
      { text: 'Hotel', align: 'center',value: 'building_7',  groupable: false },
      { text: 'Restaurant', align: 'center',value: 'building_8',  groupable: false },
      { text: 'Einkaufszentrum', align: 'center',value: 'building_9',  groupable: false },
      { text: 'Alle Gebäude', align: 'center',value: 'building_all',  groupable: false },
      { text: 'Tagesdifferenz', align: 'center',value: 'building_all_diff',  groupable: false },

    ],
    member: [

    ],

    epoList: [{
      "value": 1,
      "text": "Epo1"
    },{
      "value": 2,
      "text": "Epo2"
    },{
      "value": 3,
      "text": "Epo3"
    },{
      "value": 4,
      "text": "Epo4"
    },{
      "value": 5,
      "text": "Epo5"
    },{
      "value": 6,
      "text": "Epo6"
    },{
      "value": 7,
      "text": "Epo7"
    }],

    epoTargets: {
     1: {
       0: 7,
       1: 5,
       2: 10,
       3: 10,
       4: 10,
       5: 3,
       6: 9,
       7: 13,
       8: 20,
       9: 12,
     },
      2: {
        0: 11,
        1: 7,
        2: 15,
        3: 15,
        4: 18,
        5: 4,
        6: 15,
        7: 20,
        8: 30,
        9: 20,
      },
      3: {
        0: 15,
        1: 9,
        2: 18,
        3: 17,
        4: 20,
        5: 5,
        6: 20,
        7: 30,
        8: 32,
        9: 25,
      },
      4: {
        0: 19,
        1: 11,
        2: 22,
        3: 22,
        4: 25,
        5: 6,
        6: 25,
        7: 32,
        8: 32,
        9: 32,
      },
      5: {
        0: 22,
        1: 22,
        2: 30,
        3: 26,
        4: 30,
        5: 6,
        6: 25,
        7: 32,
        8: 32,
        9: 32,
      },
      6: {
        0: 25,
        1: 22,
        2: 30,
        3: 30,
        4: 30,
        5: 6,
        6: 25,
        7: 32,
        8: 32,
        9: 32,
      },
      7: {
        0: 25,
        1: 22,
        2: 30,
        3: 30,
        4: 30,
        5: 6,
        6: 25,
        7: 32,
        8: 32,
        9: 32,
      },
    }
  }),
  created() {
  var me = this;
    fetch(`https://blc.gamers-universe.eu/api/getPlayer.php`)
    .then((response) => response.json())
    .then((json) => {
     me.member =json;


      if(typeof me.member[0] != "undefined") {
        let currentday = parseInt(me.member[0].entryday);
        for (let i = 1; i <= currentday; i++) {
          me.dayList.push(i);
        }
      }


     me.selectedEpo = parseInt(me.member[0].epo);
     me.selectedDay = parseInt(me.member[0].entryday);
    })
    .catch((error) => console.log(error));
  },
  watch: {
    selectedDay(newvalue){
      var me = this;
      fetch(`https://blc.gamers-universe.eu/api/getPlayer.php?day=`+newvalue)
      .then((response) => response.json())
      .then((json) => {
        me.member =json;
        //me.selectedEpo = parseInt(me.member[0].epo);

      })
      .catch((error) => console.log(error));
    }
  },
  computed: {
    currentEpo(){
      if(typeof this.member[0] != "undefined") {
        return this.member[0].epo;
      } else {
        return 1;
      }
    },
  },
  methods: {
    getEpoBuildingColor (building, level,day) {
      let targetLevel;
      let epo = Math.ceil(day/7);
      if(building === 'all') {

        targetLevel = 0;

        for(let key in this.epoTargets[this.selectedEpo]){
          targetLevel += this.epoTargets[this.selectedEpo][key];
        }

      } else {
        targetLevel = this.epoTargets[this.selectedEpo][building];
      }

      if (level >= targetLevel) return 'green'
      else if (level > (building === 'all'?targetLevel-10:targetLevel-3)) return 'orange'
      else return 'red'
    },
  },
}
</script>

<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .5);
}
</style>
