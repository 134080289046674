<template>
  <v-app>


          <nav>

            <v-app-bar app flat dark clipped-left class="indigo" id="headernavbar">
              Black Lion´s Club
            </v-app-bar>


            <v-navigation-drawer app clipped dark class="indigo">
              <v-list dense nav class="lst" :height="this.$root.$children[0].appHeight">
                <List class="menu-items" :items="items" :level="0"/>
              </v-list>
            </v-navigation-drawer>
          </nav>

        <!--    <v-app-bar
              app
              color="primary"
              dark
            >
              <div class="d-flex align-center">
                <v-img
                  alt="Vuetify Logo"
                  class="shrink mr-2"
                  contain
                  src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                  transition="scale-transition"
                  width="40"
                />

                <v-img
                  alt="Vuetify Name"
                  class="shrink mt-1 hidden-sm-and-down"
                  contain
                  min-width="100"
                  src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
                  width="100"
                />
              </div>

              <v-spacer></v-spacer>

              <v-btn
                href="https://github.com/vuetifyjs/vuetify/releases/latest"
                target="_blank"
                text
              >
                <span class="mr-2">Latest Release</span>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-app-bar>-->



          <v-main class="mx-4 my-4">
            <router-view/>
          </v-main>

  </v-app>
</template>

<script>

import List from './components/Layout/List';

export default {
  name: 'App',
  components: {List},
  data: () => ({
    navigation: false,
    items: [
      {
        text: 'Dashboard',
        icon: 'mdi-view-dashboard',
        type: 'item',
        route: '/',
      },
      {
        type: 'group',
        icon: 'mdi-account ',
        text: 'Mitglieder',
        subitems: [
          {
            type: 'item',
            icon: 'mdi-view-list ',
            text: 'Bauliste',
            route: '/member/list',
          },
          {
            type: 'item',
            icon: 'mdi-chart-bar ',
            text: 'Prestige',
            route: '/member/diagramm',
          },
          {
            type: 'item',
            icon: 'mdi-chart-bar ',
            text: 'Anschlussliste',
            route: '/member/tracks',
          },
        ],
      },
      {
        type: 'group',
        icon: 'mdi-account-group ',
        text: 'Gesellschaften',
        subitems: [
         /* {
            type: 'item',
            icon: 'mdi-view-list ',
            text: 'Bauliste',
            route: '/company/list',
          },*/
          {
            type: 'item',
            icon: 'mdi-chart-bar ',
            text: 'Prestige',
            route: '/company/diagramm',
          },
        ],
      },
    ],
  }),
};
</script>
