var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v(" Anschlussliste ")]),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.trackList,"show-group-by":"","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"item.minsk_city",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.minsk_city?'green':'orange'),"dark":"","small":""}})]}},{key:"item.minsk_whz",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.minsk_whz?'green':(item.minsk_city?'red':'orange')),"dark":"","small":""}})]}},{key:"item.stp_city",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.stp_city?'green':'orange'),"dark":"","small":""}})]}},{key:"item.stp_whz",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.stp_whz?'green':(item.stp_city?'red':'orange')),"dark":"","small":""}})]}},{key:"item.moskau_city",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.moskau_city?'green':'orange'),"dark":"","small":""}})]}},{key:"item.moskau_whz",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.moskau_whz?'green':(item.moskau_city?'red':'orange')),"dark":"","small":""}})]}},{key:"item.donezk_city",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.donezk_city?'green':'orange'),"dark":"","small":""}})]}},{key:"item.donezk_whz",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.donezk_whz?'green':(item.donezk_city?'red':'orange')),"dark":"","small":""}})]}},{key:"item.kiew_city",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.kiew_city?'green':'orange'),"dark":"","small":""}})]}},{key:"item.kiew_whz",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.kiew_whz?'green':(item.kiew_city?'red':'orange')),"dark":"","small":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }